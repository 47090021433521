import React from 'react';
import useStyles from './styles.js';
import { Button, Grid, Fade } from '@material-ui/core';

const About = () => {
    const classes = useStyles();

    return (
        <>
            <div className={classes.aboutBar}>
                <picture>
                    <source media="(max-width: 960px)" srcSet="about_mobile.jpg" />
                    <source media="(min-width: 961px)" srcSet="about.jpg" />
                    <img className={classes.root} src="about.jpg" alt="about" />
                    <Fade in={true} timeout={3500}>
                        <Grid className={classes.gridBox} container justify="space-evenly" alignItems="center">
                            <Grid item className={classes.itemBoxR} xs={11} md={2}>
                                <Button style={{ color: 'white' }} className={classes.myBtn} href="https://www.linkedin.com/in/fionawho/" target="_blank" variant="contained" color="primary" size="large">LinkedIn</Button>
                            </Grid>
                            <Grid item className={classes.itemBoxL} xs={11} md={2}>
                                <Button style={{ color: 'white' }} className={classes.myBtn2} href="https://github.com/howings" target="_blank" variant="contained" color="primary" size="large">&nbsp; GitHub &nbsp;</Button>
                            </Grid>
                        </Grid>
                    </Fade>
                </picture>
            </div>
        </>
     );
}
 
export default About;