import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    heroBar: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    root: {
        display: 'flex',
        width: '100%',
        marginTop: '2em',
    },
    logo: {
        display: 'flex',
        width: '20%',
        marginTop: '7em',
        '@media (max-width:1024px)': {
            width: '35%',
        },
    },
    hero3: {
        display: 'flex',
        width: '100%',
        marginTop: '4em',
        marginBottom: '4em',
        '@media (max-width:1024px)': {
            marginTop: '2em',
            marginBottom: '2em',
        },
    },
}));