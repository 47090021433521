import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import reducers from './reducers';

import App from './App';
import './index.css';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
      type: "dark",
      background: {
        default: '#282828',
      },
      primary: {
        light: '#e2ffff',
        main: '#afe0d7',
        dark: '#7faea6',
        contrastText: '#000',
      },
      secondary: {
        light: '#ff8bae',
        main: '#ff577f',
        dark: '#c71853',
        contrastText: '#fff',
      },
      error: {
        main: '#08F791',
        contrastText: '#000',
      },
      success: {
        main: '#a024fa',
        contrastText: '#000',
      },
    },
    action: {
      selected: "#afe0d7",
    },
    typography: {
        fontFamily: [
          '"Open Sans"',
          'Poppins',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          '-apple-system',
          'BlinkMacSystemFont',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
        h1: {
            fontFamily: 'Open Sans',
            fontSize: '140px',
            fontWeight: 900,
            '@media (max-width:1440px)': {
              fontSize: '120px',
            },
            '@media (max-width:1090px)': {
              fontSize: '82px',
            },
            '@media (max-width:750px)': {
              fontSize: '53px',
            },
            '@media (max-width:380px)': {
              fontSize: '48px',
            },
            '@media (max-width:320px)': {
              fontSize: '40px',
            },
        },
        subtitle1: {
            fontFamily: 'Poppins',
            fontSize: '20px',
            fontWeight: 200,
            letterSpacing: '2px',
            margin: '1em 0',
            '@media (max-width:600px)': {
              fontize: '18px',
            },
            '@media (max-width:420px)': {
              fontSize: '16px',              
            },
        },
        subtitle2: {
            fontFamily: 'Poppins',
            fontSize: '16px',
            fontWeight: 400,
            '@media (max-width:600px)': {
              fontSize: '14px',
            },
            '@media (max-width:420px)': {
              fontSize: '12px',              
            },
            '@media (max-width:380px)': {
              fontSize: '11px',              
            },
        },
        body1: {
            fontFamily: 'Poppins',
            fontSize: '18px',
            fontWeight: 400,
        },
        body2: {
            fontFamily: 'Poppins',
            fontSize: '16px',
            fontWeight: 200,
            lineHeight: 2,
        },
        caption: {
            fontFamily: 'Poppins',
            fontSize: '12px',
            fontWeight: 500,
            textTransform: 'uppercase',
            margin: '10px',
            letterSpacing: '2px',
        },
        overline: {
            fontFamily: 'Poppins',
            fontSize: '11px',
            fontWeight: 400,
            textTransform: 'uppercase',
            letterSpacing: '1px',
        },
      },
  });

const store = createStore(reducers, compose(applyMiddleware(thunk)));

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <App />
        </Provider>
    </ThemeProvider>,
    document.getElementById('root')
);