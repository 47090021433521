import React, { createRef } from 'react';
import { creatRef } from 'react';
import useStyles from './styles.js';
import { Grid, Grow } from '@material-ui/core';

const Hero = () => {
    const classes = useStyles();
    const autoRef = createRef(false);

    return ( 
        <>
            <div className={classes.heroBar}>
                <img className={classes.logo} src='zofficeapp-icon.svg' alt='logo'/>
                <Grow in={true} timeout={3800} ref={autoRef}>
                    <Grid>
                        <img className={classes.hero3} src='hand.png' alt='hero'/>
                    </Grid>
                </Grow>
            </div>
        </>
     );
}
 
export default Hero;