import React from 'react';
import useStyles from './styles';
import {useScrollTrigger, Zoom } from '@material-ui/core'; 

const ScrollTop = (props) => {
    const classes = useStyles();
    const { children, window } = props;

    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 200,
    });

    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');
    
        if (anchor) {
          anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    return ( 
        <Zoom in={trigger}>
            <div onClick={handleClick} role="presentation" className={classes.root}>
                {children}
            </div>
        </Zoom>
     );
}
 
export default ScrollTop;