import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
    myBtn: {
        background: 'linear-gradient(160deg, #afe0d7 30%, #ff577f 90%)',
        border: 1,
        borderColor: 'white',
        color: 'white',
        height: 48,
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    },
    appBar: {
        borderRadius: 0,
        margin: '0',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#151515',
      },
      
    toolBar: {
        margin: '0',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#151515',
        color: '#f2f2f2',
    },
    heading: {
    color: '#f2f2f2',
    },
    image: {
    marginLeft: '15px',
    },
    [theme.breakpoints.down('xs')]: {
        mainContainer: {
        flexDirection: 'column-reverse'
        }
    },
    container: {
        height: '81vh',
        padding: theme.spacing(8, 0, 6),
        margin: 'auto',
    },
    container2: {
        height: 'auto',
        padding: theme.spacing(8, 0, 6),
        margin: 'auto',
        backgroundColor: '#000',
    },
    container3: {
        height: 'auto',
        padding: theme.spacing(8, 3, 4),
        margin: 'auto',
        '@media (max-width:1024px)': {
            padding: theme.spacing(4, 3, 1),
        },
    },
    container4: {
        height: 'auto',
        padding: theme.spacing(8, 3, 4),
        backgroundColor: '#2c2733',
        margin: 'auto',
        '@media (max-width:1024px)': {
            padding: theme.spacing(4, 3, 1),
        },
    },
    container5: {
        height: 'auto',
        padding: theme.spacing(8, 3, 4),
        margin: 'auto',
        '@media (max-width:1024px)': {
            padding: theme.spacing(4, 3, 1),
        },
    },
    hero: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '-15%',
        '@media (max-width:1000px)': {
            marginTop: '-35%',
        },
        '@media (max-height:650px) and (min-width:720px)': {
            marginTop: '-10%',
            marginBottom: '10%',
        },
        '@media (max-height:650px) and (min-width:1000px)': {
            marginTop: '-5%',
            marginBottom: 0,
        },
    },
    hero2: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    centerH: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
    },
    centerH2: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        minHeight: '100vh',
        backgroundColor: '#000',
        '@media (max-width:1024px)': {
            minHeight: '50vh',
        },
    },
    moreMarginTop: {
        marginTop: '15%',
        marginBottom: '8%',
    },
    smallMarginBottom: {
        marginBottom: '2em',
    },
    bgColor: {
        backgroundColor: '#151515',
    },
    loadProjects: {
        color: '#08f791'
    },
    blocks100vw: {
        display: 'block',
        width: '100%',
    },
    blocks100p: {
        display: 'block',
        width: '100%',
    },
}));