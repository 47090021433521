import { useEffect } from 'react';
import useStyles from './styles';
import { Container, Typography, Paper, Fab } from '@material-ui/core'; 
import Footer from '../components/Footer/Footer';
import Hero from '../components/Hero/Hero';
import ScrollTop from '../components/ScrollTop/ScrollTop';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const CaseStudy = (props) => {

    const classes = useStyles();

    window.scrollTo(0, 0);

    const alan = document.querySelectorAll('div.alanBtn-root');

    const alanCheck = () => {
        if(alan[0] === undefined) {
            return
        } else {
            alan[0].style.display = 'none';
        };
    }

    useEffect(() => {
        alanCheck();
    }, []);

    return ( 
        <>
        <div className={classes.container2}>
            <Container maxWidth="lg" className={classes.centerH2}>
                <Hero />
            </Container>
        </div>
        <div>
            <Container maxWidth="md" className={classes.container3}>
                <Typography variant="h4" color="primary">App Title: ZOffice</Typography>
                <Typography variant="subtitle1" color="textSecondary">Virtual Office & Team-building app combined with Facial recognition tracking system makes remote work closer to perfection.</Typography>
                <Typography variant="body1" color="textPrimary">Introduction:</Typography>
                <Typography variant="body2" color="primary" paragraph>“Hybrid models of remote work are likely to persist in the wake of the pandemic, mostly for a highly educated, well-paid minority of the workforce.” --- McKinsey.com</Typography>
                <Typography variant="body2" color="textSecondary" paragraph>How about the rest of the workforce? Due to the pandemic, it has become very clear that the interfaces of physical offices and remote work systems are very far apart.</Typography>
                <Typography variant="body2" color="textSecondary" paragraph>As of the time this case study is created, Google CEO Sundar Pichai has just detailed a new ‘hybrid' work model for its employees - 60% of the staff could work in offices "a few days a week" while 20% could work remotely, and 20% could work at home permanently. This plan is still quite complex and may have unanticipated results or impacts.</Typography>
                
                <div className={classes.smallMarginBottom}></div>
                <Typography variant="body1" color="textPrimary">Challenge:</Typography>
                <Typography variant="body2" color="primary" paragraph>Filling the physical and virtual office gap (from both Business Owners’ POV and Organlizations’ POV)</Typography>
                <Typography variant="body2" color="textSecondary" paragraph>Initial solution (Business Owners’ POV = Narrower View): Create an Online Virtual Office with an integrated Clock-in system.  Virtual reality and web technologies provide exceptional opportunities for solving this global workforce problem. Utilizing the virtual office interface, employers can physically see the presence of the teams plus directly interact with them anytime, just as they used to.</Typography>
                <Typography variant="body2" color="textSecondary" paragraph>Ideal Solution (Organizations' POV = Broader View): ZOffice Web Application.  In the virtual space, we aimed to enhance user experiences by integrating human-to-human interactions into the virtual office interface, allowing employers to physically see the presence of the team while employees can directly collaborate with their co-workers in one place, just as they used to.</Typography>
            </Container>
        </div>

        <div width="100%" style={{ backgroundColor:"#2c2733" }}>        
            <Container maxWidth="md" className={classes.container4}>    
                <div className={classes.smallMarginBottom}></div>
                <Typography variant="body1" color="textPrimary">How to fix it?</Typography>
                <Typography variant="body2" color="primary">A Real-time Office Floorplans</Typography>
            </Container>
                
                <div align="center">
                    <img className={classes.blocks100vw} src='zoffice_mockups.jpg' alt='app mockups'/>
                    <Typography variant="caption" color="primary">ZOffice - Low Fidelity Mockups for Mobile Version.</Typography>
                </div>
            
            <Container maxWidth="md" className={classes.container4}>
                <Typography variant="body2" color="textSecondary" paragraph>On gamified office floors, employees can meet with management/colleagues either from their own home offices or physical meeting rooms, thus promoting both physical and virtual workspace engagement.</Typography>
                <Typography variant="body2" color="textSecondary" paragraph>To verify the presence of team members, ZOffice incorporates WebRTC technology and LUXAND face recognition APIs to capture each user's “face” via webcam captures over a pre-defined time interval.</Typography>
                <Typography variant="body2" color="textSecondary" paragraph>Facial Recognition technology simplifies the login process and also provide a set of control tool to the management team under a hybrid remote work model. Employees should also be very familiar with the built-in Real-time collaboration functions such as file sharing, text/call chats, and video meetings.</Typography>
                <Typography variant="body2" color="textSecondary" paragraph>To verify the presence of team members, ZOffice incorporates WebRTC technology and LUXAND face recognition APIs to capture each user's “face” via webcam captures over a pre-defined time interval.</Typography>
                
                <div align="center">
                    <img className={classes.blocks100p} src='sketches.jpg' alt='storyboard'/>
                    <Typography variant="caption" color="primary">Story Boarding - Visualization and how the user interacts with the application.</Typography>
                </div>

                <div className={classes.smallMarginBottom}></div>
                <Typography variant="body1" color="textPrimary">Streamline the hybrid work model</Typography>
                <Typography variant="body2" color="textSecondary" paragraph>Whenever possible, employees should have the option of working full-time in the office rather than through the ZOffice app by acknowledging that it is a lightweight performance tracking system that yields much greater flexibility, for example over the time-consuming home-office-home daily commute.</Typography>
                <Typography variant="body1" color="error">Conclusion - Win-Win-Win!</Typography>

                <div className={classes.smallMarginBottom}></div>
                <Typography variant="body1" color="textPrimary">Result: </Typography>
                <Typography variant="body2" color="textSecondary" paragraph>ZOffice is a fully functional web application equipped with features such as real-time image capture for employee performance tracking, public chatroom, and team building. A promotional video is also created.  Due to budget and time constraints, file sharing, video conference functions, and final deployment are postponed.</Typography>

                <div align="center">
                    <img className={classes.blocks100p} src='zoffice2.jpg' alt='zoffice mockup visual'/>
                    <Typography variant="caption" color="primary">ZOffice - Visual</Typography>
                </div>

                <div className={classes.smallMarginBottom}></div>
                <Typography variant="body1" color="textPrimary">Basic Use case:</Typography>
                <Typography variant="body2" color="primary">Actor – Entire Sales Team from X company</Typography>
                <Typography variant="body2" color="primary">Stakeholder – Owner of X company</Typography>
                <Typography variant="body2" color="primary">Primary Actor – Regional Manager from X company</Typography>
                <Typography variant="body2" color="primary">Preconditions – COVID-19 lock-down part of the province prevents workers to physically visit the X company office1 while office2 is still reachable.</Typography>
                <Typography variant="body2" color="primary" paragraph>Triggers – Regional Manager initiates ZOffice to better oversee the sales team performance.</Typography>

                <Typography variant="body2" color="textSecondary" paragraph>Main scenario [Basic Flow] – David and Ajuanna from office1 are working from their home office along with Cindy in office2 location are all joining into a ZOfiice meeting at 9 am with the Regional Manager who is also working from home office. Everything goes smoothly from the beginning to the end of the workday. All staff signs out at 5 pm from the application.</Typography>

                <Typography variant="body2" color="textSecondary" paragraph>Alternative path [Alternative Flow] – David and Ajuanna from office1 are working from their home office along with Cindy in office2 location are all joining into a ZOfiice meeting at 9 am with the Regional Manager who is also working from home office. Regional Manager notices David’s avatar disappears from the application floorplan after 20mins into the meeting so he quickly makes a phone call to clarify the situation right inside ZOffice.</Typography>

                <div className={classes.smallMarginBottom}></div>
                <Typography variant="body1" color="textPrimary">ZOffice development timeframe:</Typography>
                <Typography variant="body2" color="textPrimary">Planning - 2 weeks</Typography>
                <Typography variant="body2" color="textPrimary">Development - 1 week</Typography>
                <Typography variant="body2" color="textPrimary">Debug & Deploy - 3 days</Typography>

                <div className={classes.smallMarginBottom}></div>
                <Typography variant="body1" color="textPrimary">Technology Stack - MERN</Typography>
                <Typography variant="body2" color="textPrimary">Frontend: HTML, CSS, Javascript, React, Redux, React-Bootstrap, Sass</Typography>
                <Typography variant="body2" color="textPrimary">Backend: Node, Express, MongoDB, REST, WebRTC, GraphQL</Typography>

                <div className={classes.smallMarginBottom}></div>
                <Paper variant="elevation" elevation={5} component="div" style={{ margin: "4em 0", padding: "1em", background: 'linear-gradient(160deg, #a024fa 30%, #08f791 90%)' }}>
                    <Typography variant="body1" color="textPrimary">My Role and the ZOffice Project Team</Typography>
                    <Typography variant="body2" color="textPrimary">Fiona Ho - Frontend & Backend Developer / Interaction Designer</Typography>
                    <Typography variant="body2" color="textPrimary">Victoria You - Frontend & Layout Developer</Typography>
                    <Typography variant="body2" color="textPrimary">Disha Shah - UX/UI Graphic Designer</Typography>
                    <Typography variant="body2" color="textPrimary">Cora Wan - Project Lead / Frontend & Backend Developer</Typography>
                </Paper>
            </Container>
        </div>
        <div>
            <Footer />
        </div>
        <ScrollTop {...props}>
            <Fab color="secondary" size="large" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
            </Fab>
        </ScrollTop>
        </>
     );
}
 
export default CaseStudy;