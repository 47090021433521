import React from 'react';
import useStyles from './styles.js';
import { Grid, Container, Typography, Fade } from '@material-ui/core';


const Tech = () => {
    const classes = useStyles();
        
    return ( 
        <div className={classes.techBar}>
            <Container maxWidth='lg'>
                <Typography variant="overline" align="center" color="textSecondary" >
                THIS SITE IS CODED & BUILT FROM SCRATCH BY ME WITH:
                </Typography>
                <Fade in={true} timeout={3500}>
                <Container className={classes.root}>
                    <Grid container direction="row" justify="space-evenly" alignItems="center" spacing={3}>
                        <Grid item style={{ maxWidth: '5em' }} xs={12} sm={8}>
                            <img src="logos-node.svg" alt="node"/>
                        </Grid>
                        <Grid item style={{ maxWidth: '5em' }} xs={12} sm={8}>
                            <img src="logos-express.svg" alt="express"/>
                        </Grid>
                        <Grid item style={{ maxWidth: '5em' }} xs={12} sm={8}>
                            <img src="logos-react.svg" alt="react"/>
                        </Grid>
                        <Grid item style={{ maxWidth: '5em' }} xs={12} sm={8}>
                            <img src="logos-mongodb.svg" alt="mongodb"/>
                        </Grid>
                        <Grid item style={{ maxWidth: '5em' }} xs={12} sm={8}>
                            <img src="logos-mui.svg" alt="mui"/>
                        </Grid>
                        <Grid item style={{ maxWidth: '5em' }} xs={12} sm={8}>
                            <img src="logos-html.svg" alt="html"/>
                        </Grid>
                        <Grid item style={{ maxWidth: '5em' }} xs={12} sm={8}>
                            <img src="logos-css.svg" alt="css"/>
                        </Grid>
                        <Grid item style={{ maxWidth: '5em' }} xs={12} sm={8}>
                            <img src="logos-js.svg" alt="js"/>
                        </Grid>
                    </Grid>
                </Container>
                </Fade>
            </Container>
        </div>
    );
}
 
export default Tech;