import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    aboutBar: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // paddingTop: '8%',
        // paddingBottom: '8%',
        backgroundColor: '#151515',
    },
    root: {
        display: 'flex',
        width: '100%',
        marginTop: '2em',
    },
    gridBox: {
        display: 'flex',
        position: 'absolute',
        zIndex: 10,
        marginTop: '-28%',
        flexDirection: 'row',
        '@media (max-width:961px)': {
            position: 'relative',
            flexDirection: 'column',
            marginTop: '-2em',
            marginBottom: '5em'
        },
    },
    myBtn: {
        background: 'linear-gradient(160deg, #afe0d7 30%, #ff577f 90%)',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        border: 1,
        borderColor: 'white',
        color: 'white',
        width: '100%',
        padding: '1.8em !important',
        '@media (max-width:961px)': {
            padding: '1.8em 5.5em !important',
            margin: '1em 0'
        }
    },
    myBtn2: {
        background: 'linear-gradient(160deg, #08f791 30%, #a024fa 90%)',
        boxShadow: '0 3px 5px 2px rgba(160, 36, 250, .3)',
        border: 1,
        borderColor: 'white',
        color: 'white',
        width: '100%',
        padding: '1.8em !important',
        '@media (max-width:961px)': {
            padding: '1.8em 5.5em !important',
            margin: '1em 0'
        }
    },
    buttonSubmit: {
    border: 1,
    borderColor: 'white',
    color: 'white',
    width: '100%',
    padding: '1.5em',
    '@media (max-width:961px)': {
        padding: '1.5em 5em',
        margin: '1em 0'
        }
    },
    itemBoxR: {
        marginRight: '-10em',
        '@media (max-width:1350px)': {
            marginRight: '-7em',
        },
        '@media (max-width:961px)': {
            marginRight: '0',
        }
    },
    itemBoxL: {
        marginLeft: '-10em',
        '@media (max-width:1350px)': {
            marginLeft: '-7em',
        },
        '@media (max-width:961px)': {
            marginLeft: '0',
        }
    }
}));