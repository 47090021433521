import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    techBar: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        width: '100%',
        minHeight: '20vh',
        padding: theme.spacing(3, 2),
        backgroundColor: '#2c2733',
    },
    root: {
        flexGrow: 1,
        marginTop: '1.5em',
    },
    techItems: {
        maxWidth: '5em',
    }
}));