import React from 'react';
import { useEffect, createRef } from 'react';
import ProjectReadOnly from './Project/ProjectReadOnly';
import useStyles from './styles.js';
import { useSelector } from 'react-redux';
import { Grid, Grow, Container, CircularProgress } from '@material-ui/core';
import Scroll from "react-scroll";


const ProjectsReadOnly = ({ setCurrentId }) => {
    const classes = useStyles();
    const projects = useSelector((state) => state.projects);
    const autoRef = createRef(false);
    const Element  = Scroll.Element;
    const scroller = Scroll.scroller;
    let offsetHeight = window.innerHeight*.13;

    // console.log(projects);
    const autoScroll = () => {
        // console.log("auto scroll is triggered");
        scroller.scrollTo('projectList', {
            duration: 800,
            delay: 100,
            smooth: 'easeOutCubic',
            offset: -offsetHeight,
        })
    };    

    useEffect(() => {
        // console.log(autoRef.current);
        if(!autoRef.current) return;
        
        autoScroll();
    
    }, [autoRef]);

    return ( 
        !projects.length ? <CircularProgress /> : (
            <Container className={classes.addMargin} maxWidth='lg' >
                <Element name="projectList"><h1>Project List</h1></Element>
                <Grow in={true} timeout={3800} ref={autoRef}>
                    <Grid className={classes.container} container alignItems="stretch" spacing={5}>  
                        {projects.map((project, index) => (
                            <Grid key={index} item xs={12} sm={12} md={6} lg={4} xl={4} style={{ display: 'flex' }} >
                                <ProjectReadOnly index={index} project={project} setCurrentId={setCurrentId} />
                            </Grid>
                        ))}
                    </Grid>
                </Grow>
            </Container>
        )
    );
}
 
export default ProjectsReadOnly;